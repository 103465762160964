import Image from 'next/image';
import Countdown, { zeroPad } from 'react-countdown';
import CloseIcon from '@public/icons/close.svg';

export interface NotificationProps {
  content: string | null;
  contentColor?: string;
  backgroundColor?: string;
  countdown?: Date;
  onClose?: () => void;
}

function Notification({
  content,
  contentColor,
  backgroundColor,
  countdown,
  onClose,
}: Readonly<NotificationProps>) {
  return (
    <div className="min-h-1">
      <input id="notification" type="checkbox" defaultChecked={true} />

      <div className="notification">
        <div
          className="p-[0.9375rem] flex justify-center items-start md:items-center relative"
          style={{ backgroundColor, color: contentColor }}
        >
          <Image
            src="/icons/exclamation-circle.svg"
            alt="info"
            width={15}
            height={15}
            className="filter invert-100 sepia-0 saturate-0 hue-rotate-0 brightness-200 contrast-100"
            quality={30}
            priority
          />
          <div className="inline pr-[1.875rem] ml-[0.9375rem]">
            {content ? (
              <div
                className="text-left text-sm"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            ) : (
              ''
            )}
            {countdown && (
              <Countdown
                date={countdown}
                renderer={({ days, hours, minutes, seconds }) => (
                  <div className="inline font-bold ml-[0.9375rem]">
                    {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:
                    {zeroPad(seconds)}
                  </div>
                )}
              />
            )}
          </div>
          <label htmlFor="notification" className="close">
            <CloseIcon fill="white" onClick={onClose} />
          </label>
        </div>
      </div>
    </div>
  );
}

export default Notification;
