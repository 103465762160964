import { useEffect, useState } from 'react';

import { Notification as NotificationType } from 'interfaces/cms/common';
import Notification from './Notification';

export interface NotificationBarProps {
  notifications: Array<NotificationType>;
}

const STORAGE_KEY = 'closed:notifications';

function NotificationBar({ notifications }: Readonly<NotificationBarProps>) {
  const [closed, setClosed] = useState<any>([]);

  useEffect(() => {
    const storage = window.localStorage;
    const closedString = storage.getItem(STORAGE_KEY);
    setClosed(closedString ? JSON.parse(closedString) : []);
  }, []);

  const handleClose = (id: number) => {
    // Update the state and localstorage for the closed notifications
    const newClosed = [...closed, id];
    const storage = window.localStorage;
    storage.setItem(STORAGE_KEY, JSON.stringify(newClosed));
    setClosed(newClosed);
  };

  return (
    <>
      {notifications
        .filter(
          (notification: NotificationType) => !closed.includes(notification.id)
        )
        .map((notification: NotificationType) => (
          <Notification
            key={notification.id}
            content={notification.content}
            contentColor={notification.contentColor}
            backgroundColor={notification.backgroundColor}
            countdown={
              notification.timer && notification.endDate
                ? new Date(notification.endDate)
                : undefined
            }
            onClose={() => {
              handleClose(notification.id);
            }}
          />
        ))}
    </>
  );
}

export default NotificationBar;
